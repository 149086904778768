@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1199px)";
@desktop: ~"(min-width: 1200px)";
@widescreen: ~"(min-width: 1430px)";
@mobileNavColor: #2c5ca8;
@castleDetailsColor: #2c5ca8;
@sm-only: ~"(min-width: 768px) and (max-width: 991px)";
@md-only: ~"(min-width: 992px) and (max-width: 1199px)";
@stripPadding: 45px;
@lineHeight: 165%;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/038/";
//url('@{cloudUrl}banner-bg.jpg'
/*#region fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400 !important;
	color: #5d5d5d;
	font-size: 18px;
	line-height: @lineHeight;
}

.Fredoka {
	font-family: 'Fredoka One', sans-serif;
}

html {
}

body {
	.BodyFont;
}

.nicefont {
}

h1, h2, h3, h4 {
	.Fredoka;
	font-weight: normal !important;
	font-size: 3.6rem;
	text-transform: capitalize;
	color: #ff4849;
	margin-bottom: 30px;

	@media @mobile {
		text-align: center;
		margin-bottom: 25px;
	}
}

h1 {
	font-size: 50px;
}

p {
}
/*#endregion fonts*/
form {
	max-width: 100vw;
	overflow: hidden;
}

.outer-wrapper {
	max-width: 100vw;
	position: relative;

	&--alt {
	}
}

.container {
	position: relative;

	@media @mobile {
		width: 100%;
	}

	@media @tablet {
		width: 100%;
	}

	@media @desktop {
		width: 1170px;
	}
	/*@media (min-width: 1310px) {
		padding-left: 55px;
		padding-right: 55px;
	}*/
	/*@media @widescreen {
		width: 1400px;
	}*/
	&--content {
		flex-grow: 1;
	}
}

.outer-wrapper--alt {
	.main-content__girl {
		display: none;
	}

	.pageListingsSection__boy {
		display: none;
	}

	.header__wrapper {
		@media @mobile {
			margin-bottom: 0px;
		}
	}

	.main-content__wrapper {
		padding: 0px;
	}

	.main-content__video {
		display: none;
	}

	.pageContent {
		margin-bottom: 30px;
	}
}

.header {

	&__main {
		margin-top: 20px;

		@media @mobile {
			margin-top: 30px;
		}

		.HoverBounce {
			display: inline-block !important;
		}
	}

	&__row {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		@media @mobile{
			justify-content:center;
		}
	}

	&__row-ws {
		.header__message-col {
			order: 3;

			@media @md {
				order: 1;
			}
		}

		.header__logo-box {
			order: 1;

			@media @md {
				order: 2;
			}
		}

		.header__phone-col {
			order: 2;

			@media @md {
				order: 3;
			}
		}
	}

	&__text {
		font-size: 14px;
		padding-top: 3px;

		@media @sm-only {
			text-align: center;
			padding: 0 0 10px 0;
		}

		@media @mobile {
			text-align: center;
		}
	}

	&__bar {
		background-color: #2b2b2b;
		color: white;
		padding: 18px 0px;

		@media @sm-only {
			padding: 10px 0px;
		}

		@media @mobile {
			margin-top: 54px;
		}
	}

	&__weatherChecker {
		width: 100%;
		text-align: center;
	}

	&__search {
		@media @sm-only {
			padding-bottom: 10px;
		}

		@media @mobile {
			display: none;
		}

		.SearchPanel {
			.SearchBoxTextBox {
				border-radius: 8px;
				border: none;
				color: #000;
				padding: 5px 0px 5px 10px;
				width: 100%;
				font-size: 14px;
			}

			.SearchProductsButton {
				background: #c61fa1;
				border-radius: 5px;
				padding: 1px 28px;
				font-size: 18px;
				position: absolute;
				right: 22px;
				top: 4px;
				color: #fff;
				.Fredoka;
			}
		}
	}

	&__phone-info {
		margin-top: 40px;

		@media @mobile {
			display: block;
			text-align: center;
			margin-top: 20px;
		}
	}

	&__phone {
		img {
			@media @mobile {
				position: relative;
				right: 0;
			}
		}
	}

	&__social-row {
		margin-top: 25px;

		@media @mobile {
			margin-top: 15px;
		}
	}

	&__social-links {
		@media @mobile {
			display: block;
			margin: 10px auto;
			text-align: center;
		}
	}

	&__social-link {
		display: inline-block;
		text-align: center;

		@media @sm {
			width: 32%;
		}

		img {
			border-radius: 50%;
			border: 3px solid #fff;
			width: 70px;
			height: 70px;

			@media @sm {
				width: auto;
				height: auto;
			}
		}
	}

	&__all-products {
		.Fredoka;
		background-color: #f7c500;

		@media @tablet {
			font-size: 1vw;
			width: 100%;
			padding: 14px 0px 14px 0px;
		}

		@media @desktop {
			font-size: 11.3px;
			padding: 16px 12.5px;
			letter-spacing: 1.1px;
		}

		@media @mobile {
			display: block;
			margin-top: 22px;
			padding: 12px 0px;
			font-size: 20.3px;
		}

		@media @sm-only {
			font-size: 1.7vw;
			width: 100%;
			padding: 19px 0 19px 0;
		}
	}

	&__wrapper {
		margin-bottom: 30px;

		@media @mobile {
			margin-bottom: 0px;
		}
	}

	&__call {
		position: relative;
		max-width: 170px;

		&:before {
			content: "";
			display: block;
			width: 100%;
			padding-top: 22.3529%
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__weather {
		max-width: 461px;
		position: relative;
		display: block;
		margin: 16px auto 0;

		&:before {
			content: "";
			display: block;
			width: 100%;
			padding-top: 16.4859%
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__message {
		.Fredoka;
		color: #ff4849;
		text-transform: uppercase;
		font-size: 22px;
		margin-top: 30px;
		text-align: center;

		@media @mobile{
			margin-bottom:30px;
		}

		@media @md {
			margin-top: 0;
			text-align: left;
		}
	}
}

.widgets {
	display: none;

	@media @tablet {
		display: block;
		background-color: #ff4849;
		border-radius: 5px;
		padding: 17px;
		margin-top: 50px;
		padding-bottom: 74px;

		&__text {
			display: block;
			margin-bottom: 20px;
		}

		&__items {
		}

		&__area-checker {
			width: 50%;
		}

		.form-control {
			font-size: 12px;
		}

		.SearchDate {
			width: 40%;
			border-radius: 5px;
			border: none;
			padding: 2.19px;
			padding-left: 15px;
			padding-top: 3px;
			position: absolute;
			right: 28px;
			bottom: 74px;
			font-size: 12px;
		}

		.SearchCategory {
			width: 58%;
			border-radius: 5px;
			border: none;
			padding: 7.5px;
			position: absolute;
			margin-top: 14px;
			font-size: 12px;

			@media @md-only {
				width: 50%;
			}
		}

		.SearchButton {
			color: #fff;
			background-color: #ffa013;
			border-radius: 5px;
			text-shadow: 0 1px 0 rgba(0,0,0,.2);
			text-transform: uppercase;
			font-size: 2rem;
			position: absolute;
			right: 28px;
			bottom: 25.5px;
			padding: 3px 9px;
			.Fredoka;
		}
	}
}
/*#region topLinksMobile*/
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white !important;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 10px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion topLinksMobile*/
.toplinks {
	margin: 20px 0px;

	&__nav {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		position: relative;
		list-style: none;
		text-align: center;
		flex-grow: 1;
		.colorButtons(@colors;@length;@index);

		&:first-child .toplinks__link {
			margin-left: 0px;
		}

		&:last-child .toplinks__link {
			margin-right: 0px;
		}

		.dropdown-toggle:focus {
			text-decoration: none;
		}
	}

	&__link {
		position: relative;
		display: block;
		padding: 12px 0px;
		color: #fff;
		transition: all 0.3s;
		font-size: 2.25rem;
		.Fredoka;
		border-radius: 10px;
		margin: 0px 10px;
		z-index: 10;

		@media @sm-only {
			font-size: 1.65rem;
		}

		&:hover, &.active, &:focus {
			color: #fff;
		}
	}

	&__dropdown {
		padding: 0;
		margin: 0;
		border: none;
		box-shadow: none;
		text-align: center;
		border-radius: 8px;
		margin-top: 5px;

		@media @desktop {
			margin-left: 10px;
		}

		@media @md-only {
			margin-left: 10px;
		}
	}

	&__dropdown-li {
		display: block;
	}

	&__dropdown-link {
		position: relative;
		padding: 7px 10px !important;
		font-size: 1.6rem;
		color: #fff !important;
		.Fredoka;
		background-color: transparent !important;

		&:hover, &:focus, &.active {
			color: #fff !important;
		}
	}
}

.banner {
	.BannerPanelSet(64.6666%);

	.carousel-inner {
		.item, a, img {
			height: 100%;

			a, img {
				width: 100%;
				height: 100% !important;
				display: inline-block;
				object-fit: contain;
			}
		}
	}

	&__container {
		z-index: 1;
	}

	&__wrapper {
		margin: -50px 0px 0px;
		padding: 100px 0 80px;
		background-color: #5b44ae;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			background-image: url('@{cloudUrl}banner-lower.png');
			bottom: 0;
			height: 59px;
			width: 100%;
			background-size: auto 100%;
			background-position: center center;
			transform: translateY(100%);

			@media @mobile {
				display: none;
			}
		}

		&:before {
			.StickToEdges;
			background-image: url('@{cloudUrl}banner-background.png');
			bottom: -48px;
			z-index: 1;
		}

		@media @mobile {
			margin: 0px;
			padding: 0px;
		}
	}

	&__title {
		color: #fff;
		font-size: 4.6rem;
		.Fredoka;
		line-height: 5rem;
		margin-bottom: 25px;

		@media @mobile {
			text-align: center;
			margin-top: 50px;
			margin-bottom: 25px;
		}
	}

	&__desc {
		color: #fff;
		font-size: 2.2rem;

		@media @mobile {
			text-align: center;
		}
	}

	&__btn {
		padding: 15px 32px;
		font-size: 2rem;
		margin-top: 56px;
		background-color: #ff4849;
		.Fredoka;
		z-index: 10;
		position: relative;

		@media @mobile {
			margin: 35px 0px;
			display: block;
		}
	}

	&__btn:hover {
		background-color: #ff2e2e;
	}

	#CarouselContainer {
		max-height: none !important;
		max-width: none !important;

		@media @mobile {
			margin-bottom: 50px;
		}
	}

	.carousel {
		.item {
			height: 100%;
		}

		img {
			width: 100% !important;
			height: 100% !important;
			object-fit: contain;
			object-position: center center;
		}
	}
}

.featured-cats {
	margin-top: -55px;
	z-index: 10;

	@media @sm-only {
		margin-top: -40px;
	}

	@media @mobile {
		margin-top: 20px;
	}

	&__panel {
		padding: 25px 15px;
		border-radius: 25px;
		min-height: 485px;
		text-align: center;

		@media @sm-only {
			padding: 25px 15px;
			min-height: 0px;
			margin-bottom: 35px;
		}

		@media @mobile {
			padding: 0 25px 0 25px;
			min-height: 0px;
			margin-bottom: 35px;
		}
	}

	&__panel-parent {
		&:nth-child(1) {
			.featured-cats__panel {
				background-color: #219cfc;
			}

			.featured-cats__btn {
				background-color: #c429a0;
				border-color: #c429a0;
			}
		}

		&:nth-child(2) {
			.featured-cats__panel {
				background-color: #9dc536;
			}

			.featured-cats__btn {
				background-color: #219cfc;
				border-color: #219cfc;
			}
		}

		&:nth-child(3) {
			.featured-cats__panel {
				background-color: #fd7232;
			}

			.featured-cats__btn {
				background-color: #9dc536;
				border-color: #9dc536;
			}
		}

		&:nth-child(4) {
			.featured-cats__panel {
				background-color: #c429a0;
			}

			.featured-cats__btn {
				background-color: #fd7232;
				border-color: #fd7232;
			}
		}
	}

	&__title {
		.Fredoka;
		text-transform: uppercase;
		color: #fff;
		font-size: 3.5rem;
		margin: 10px 0px 28px;
		line-height: 1;

		@media @mobile {
			margin: 10px 0 25px;
			padding-top: 25px;
		}

		@media @sm-only {
			padding: 0 40px 0 40px;
		}
	}

	&__desc {
		color: #fff;
		font-size: 18px;
		margin-bottom: 36px;
	}

	img {
		min-height: 163px;
	}

	&__btn {
		color: #fff;
		padding: 10px 30px;
		font-size: 2rem;
		.Fredoka;
		top: 45px;
		position: relative;

		@media @md-only {
			top: 45px;
		}

		@media @sm-only {
			top: 45px;
		}

		@media @mobile {
			top: 20px;
		}
	}

	&--multiple {
		.BannerPanelSet(71.75%);

		.carousel {
			padding-top: 71.75%;
			overflow: hidden;

			.carousel-inner {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;

				.item, a, img {
					width: 100%;
					height: 100%;
				}

				img {
					object-fit: contain;
				}
			}
		}
	}
}

.main-content {
	&__wrapper {
		padding: @stripPadding;

		@media @mobile {
			padding: 0px;
		}
	}

	&__video {
		margin-top: 70px;

		@media @mobile {
			margin-top: 25px;
			margin-bottom: 40px;
		}
	}

	&__girl {
		display: none;

		@media @desktop {
			display: block;
			position: absolute;
			left: -20px;
			top: 120px;
		}
	}
}

.pageContent {
}

#BodyContent {
	background-color: #fff;

	@media @mobile {
		border-top-width: 0px;
	}

	@media @sm {
		padding-bottom: 50px;
	}
}

.pageListingsSection {
	background-color: #ff4849;
	padding-bottom: 65px;
	position: relative;

	&__header {
		color: #fff;
		text-align: center;
		font-family: 'Fredoka One',sans-serif;
		margin: 40px 0 40px;
		font-size: 4rem;

		@media @mobile {
			margin: 35px 0 35px;
			font-size: 3.4rem;
		}
	}

	&__boy {
		display: none;

		@media @desktop {
			display: block;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}

.castlePanel {
	width: 100%;
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	border-radius: 8px;

	@media @mobile {
		margin: 0px 0px 15px;
	}

	&:hover {
		.SingleImagePanel {
			img {
				transform: none;
				animation-name: jello;
				transform-origin: center;
				animation-duration: 0.5s;
				animation-fill-mode: both;
			}
		}
	}

	.SingleImagePanel {
		height: auto;
		padding-top: 50%;
		border-radius: 8px;

		&:before {
			content: "";
			display: block;
			padding-top: 50%;
			width: 100%;
		}
	}

	.castleTitlePanel {
		margin: 10px 0 0;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;
		.Fredoka;

		a {
			color: #000;
			font-size: 1.8rem;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0;
		margin-top: 10px;
		//font-size: 1rem;
		span.oldprice {
			//font-size: 0.65rem;
		}

		span.newprice {
			//font-size: 0.8rem;
		}
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		margin: 8px 38px;
		height: auto;
		background-color: #c049ff;
		border-radius: 8px;
		padding: 12px;
		padding: 7px;
		font-size: 1.6rem;

		@media @mobile {
			margin: 12px 15px;
		}
	}
}

#Testimonials {
	position: relative;

	h2 {
		text-align: center;
	}

	.TestimonialContainer {
		color: #000 !important;
		background-color: #fff;
		.box-shadow(0 0 20px 0 rgba(0,0,0,0.1));
		border-radius: 8px;
		padding: 65px 32px 65px;

		@media @mobile {
			padding: 13px 15px;
		}
	}

	#TestimonialContent {
		padding: 55px 0px;
		background-color: #162050;

		@media @tablet-only {
			padding-left: 35px;
			padding-right: 35px;
		}

		@media @mobile {
			padding: 20px 0px;
		}

		.Testimonial {
			text-decoration: none;
			text-align: center;
			display: block;
			overflow: hidden;

			@media @tablet {
				padding-bottom: 25px;
			}

			&:hover {
				text-decoration: none;
			}

			p {
				font-size: 25px;
				color: #7bba02;
				text-decoration: none;
				text-align: center;

				&:nth-child(1) {
					color: #000;
					font-size: 20px;
					font-style: italic;
				}
			}
		}
	}
}

.enquiryform {
	background-color: #f7c500;

	&__container {
		@media @sm-only {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__form-wrapper {
		margin-top: 27px;

		@media @mobile {
			margin-top: 15px;
		}
	}

	&__image-left {
		@media @desktop {
			display: block;
			position: absolute;
			left: -85px;
			top: 84px;
			-webkit-transform: scale(.9);
			transform: scale(1.2);
		}

		@media @md-only {
			display: block;
			position: absolute;
			left: 0px;
			top: 84px;
			-webkit-transform: scale(.9);
			transform: scale(1);
		}
	}

	.sectionTitle {
		display: none;
	}

	.Label-YourName, .Label-YourEmailAddress,
	.Label-YourPhoneNumber, .Label-Message, .Label-YourLocation, .Label-Name, .Label-Email,
	.Label-Phone, .Label-Message, .Label-Location {
		display: none;
	}

	.ControlYourPhoneNumber, .ControlYourName,
	.ControlYourEmailAddress, .ControlMessage, .ControlYourLocation, .ControlPhone, .ControlName, .ControlEmail {
		background-color: #fff;
		color: #181818;
		border: 1px solid #fff;
		border-radius: 5px;
		width: 100%;
		height: 60px;

		&::placeholder {
			color: #181818;
		}
	}

	.ControlMessage {
		height: 120px;
	}

	.innerColumn {
		padding: 0px;

		.col-sm-6 {
			width: 100%;
		}
	}

	.booking-form {
		max-width: 540px;
		margin: 0px;
		border: 0;
		background-color: transparent;
		border-radius: 0 0 0 0;
		color: #4d4d4d;
		box-shadow: 0px 0px 0px #000;

		@media @mobile {
			margin: 0 auto 30px;
		}
	}

	h3 {
		.Fredoka;
		font-size: 5rem;
		color: #181818;
	}

	p {
		font-size: 18px;
		margin: 30px 0px;
	}

	&__form-text {
		margin-bottom: 30px;
		max-width: 540px;

		@media @mobile {
			text-align: center;
			margin: 0 auto 30px;
		}
	}

	input[type='button'] {
		.Fredoka;
		color: white;
		background-color: #181818;
		letter-spacing: 2px;
		font-size: 2rem;
		padding: 15px;
		margin: 20px 0px 40px;
		width: 38%;
		border-color: #181818;

		@media @mobile {
			padding: 8px;
			margin: 20px 0 0;
			width: 60%;
		}
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 180px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}
}

.footer {
	&__top-wrapper {
		background: #5640a9;
		padding: 50px 0px 25px;

		@media @mobile {
			text-align: center;
		}
	}

	&__bottom-wrapper {
		background: #3F4040;
		padding: 20px 0px;

		@media @mobile {
			text-align: center;
		}
	}

	&__call-info {
		@media @mobile {
			text-align: center;
			margin-top: 20px;
		}
	}

	&__call {
		position: relative;

		@media @mobile {
			position: relative;
			left: 42px;
		}
	}

	&__phone {
		img {
			position: relative;
			right: 22px;
			width: 85%;

			@media @mobile {
				position: relative;
				right: 0;
			}
		}
	}

	&__logos {
		ul {
			display: flex;
			padding: 0px 0px 0px 0px;
			list-style: none;
			flex-wrap: wrap;

			@media @mobile {
				justify-content: center;
			}

			li {
				list-style: none;
			}
		}

		li img, a {
			display: inline-block;
			color: white;
			text-align: center;
			margin: 5px;
			text-decoration: none;
		}

		#HTTPS {
			width: 95px;
			height: 75px;
		}

		#BIHA {
			width: 65px;
			height: 70px;
		}

		#TIPE {
			width: 67px;
			height: 70px;
		}

		#PIPA {
			width: 109px;
			height: 70px;
		}
	}

	#BCNLink {
		@media @tablet {
			justify-content: end;
			display: grid;
		}

		@media @mobile {
			justify-content: center;
			display: grid;
		}
	}

	&__logo-container {
		padding: 0px 0px;
	}

	&__Https {
		position: relative;
		top: 10px;
	}

	&__email {
		color: #fff;
		font-size: 2rem;
		margin-top: 25px;
		.Fredoka;
		letter-spacing: 1.6px;

		&:hover {
			color: #fff;
		}

		@media @tablet {
			font-size: 1.8rem;
		}

		@media @desktop {
			font-size: 2rem;
		}
	}

	&__links {
		display: flex;
		justify-content: center;
		margin: 10px 0;
	}

	&__link {
		color: #fff;
		position: relative;
		display: table;
		margin: 0 5px;
		padding: 10px 5px;

		&:after {
			content: '';
			position: absolute;
			bottom: -.2rem;
			left: 0;
			right: 100%;
			height: 0px;
			background: rgba(255,255,255,1);
			//hover out transitions
			transition: left 2s, right 2s, height 2s, opacity 1s;
			opacity: 0;
		}

		&:hover {
			color: #fff;
			text-decoration: none !important;

			&:after {
				left: 0;
				right: 0;
				height: 2px;
				opacity: 1;
				transition: all 0.5s;
				// hover in transitions
				transition: left 0.5s, right 0.5s, height 0.5s,opacity 1s;
			}
		}
	}

	&__social {
		display: flex;
		justify-content: space-between;

		@media @mobile {
			justify-content: center;
		}

		&-link img {
			margin: 0 2px;
			max-width: 40px !important;

			@media @mobile {
				margin: 0 4px;
			}
		}
	}

	&__row {
		color: #a1a1a1;

		p, a {
			color: #a1a1a1;
		}

		align-content:hover {
			color: #a1a1a1;
			text-decoration: none;
		}
	}

	&__booking-online {
		@media @sm {
			text-align: right;
		}
	}

	&__navigation-links a {
		color: #fff;
	}
}

.section-title {
	.Fredoka;
	color: #fff;
	font-size: 2.4rem;
	text-transform: uppercase;
}

@colors: #005aaa, #65bc45, #ffd909, #ec0b8e, #e83b4d;
@length: length(@colors);
@index: length(@colors);

.colorButtons(@colors; @length; @index) when (@index > 0) {
	.colorButtons(@colors; @length; (@index - 1));
	@color: extract(@colors,@index);

	&:nth-child(@{length}n + @{index} ) a {
		background-color: @color;

		&:hover {
			background-color: (lighten(@color,10%)) !important;
		}
	}

	&:nth-child(@{length}n + @{index} ) .toplinks__dropdown {
		background-color: @color !important;
	}
}

.colorBack(@colors; @length; @index) when (@index > 0) {
	.colorBack(@colors; @length; (@index - 1));
	@color: extract(@colors,@index);
	background-color: @color;
}

.lower-content {
	background: #fff;
	border-radius: 8px;
	padding: 10px 20px 15px;
	margin-top: 40px;
}


/*#region Animations*/


@keyframes jello {
	from, 11.1%, to {
		transform: translate3d(0, 0, 0);
	}

	22.2% {
		transform: skewX(-12.5deg) skewY(-12.5deg);
	}

	33.3% {
		transform: skewX(6.25deg) skewY(6.25deg);
	}

	44.4% {
		transform: skewX(-3.125deg) skewY(-3.125deg);
	}

	55.5% {
		transform: skewX(1.5625deg) skewY(1.5625deg);
	}

	66.6% {
		transform: skewX(-0.78125deg) skewY(-0.78125deg);
	}

	77.7% {
		transform: skewX(0.390625deg) skewY(0.390625deg);
	}

	88.8% {
		transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
	}
}

/*#endregion Animations*/
