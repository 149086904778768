/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
  color: #5d5d5d;
  font-size: 18px;
  line-height: 165%;
}
.Fredoka {
  font-family: 'Fredoka One', sans-serif;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
  color: #5d5d5d;
  font-size: 18px;
  line-height: 165%;
}
h1,
h2,
h3,
h4 {
  font-family: 'Fredoka One', sans-serif;
  font-weight: normal !important;
  font-size: 3.6rem;
  text-transform: capitalize;
  color: #ff4849;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  h1,
  h2,
  h3,
  h4 {
    text-align: center;
    margin-bottom: 25px;
  }
}
h1 {
  font-size: 50px;
}
/*#endregion fonts*/
form {
  max-width: 100vw;
  overflow: hidden;
}
.outer-wrapper {
  max-width: 100vw;
  position: relative;
}
.container {
  position: relative;
  /*@media (min-width: 1310px) {
		padding-left: 55px;
		padding-right: 55px;
	}*/
  /*@media @widescreen {
		width: 1400px;
	}*/
}
@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container--content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.outer-wrapper--alt .main-content__girl {
  display: none;
}
.outer-wrapper--alt .pageListingsSection__boy {
  display: none;
}
@media (max-width: 767px) {
  .outer-wrapper--alt .header__wrapper {
    margin-bottom: 0px;
  }
}
.outer-wrapper--alt .main-content__wrapper {
  padding: 0px;
}
.outer-wrapper--alt .main-content__video {
  display: none;
}
.outer-wrapper--alt .pageContent {
  margin-bottom: 30px;
}
.header__main {
  margin-top: 20px;
}
@media (max-width: 767px) {
  .header__main {
    margin-top: 30px;
  }
}
.header__main .HoverBounce {
  display: inline-block !important;
}
.header__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 767px) {
  .header__row {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.header__row-ws .header__message-col {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}
@media (min-width: 992px) {
  .header__row-ws .header__message-col {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}
.header__row-ws .header__logo-box {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
@media (min-width: 992px) {
  .header__row-ws .header__logo-box {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}
.header__row-ws .header__phone-col {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
@media (min-width: 992px) {
  .header__row-ws .header__phone-col {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}
.header__text {
  font-size: 14px;
  padding-top: 3px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .header__text {
    text-align: center;
    padding: 0 0 10px 0;
  }
}
@media (max-width: 767px) {
  .header__text {
    text-align: center;
  }
}
.header__bar {
  background-color: #2b2b2b;
  color: white;
  padding: 18px 0px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .header__bar {
    padding: 10px 0px;
  }
}
@media (max-width: 767px) {
  .header__bar {
    margin-top: 54px;
  }
}
.header__weatherChecker {
  width: 100%;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .header__search {
    padding-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .header__search {
    display: none;
  }
}
.header__search .SearchPanel .SearchBoxTextBox {
  border-radius: 8px;
  border: none;
  color: #000;
  padding: 5px 0px 5px 10px;
  width: 100%;
  font-size: 14px;
}
.header__search .SearchPanel .SearchProductsButton {
  background: #c61fa1;
  border-radius: 5px;
  padding: 1px 28px;
  font-size: 18px;
  position: absolute;
  right: 22px;
  top: 4px;
  color: #fff;
  font-family: 'Fredoka One', sans-serif;
}
.header__phone-info {
  margin-top: 40px;
}
@media (max-width: 767px) {
  .header__phone-info {
    display: block;
    text-align: center;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .header__phone img {
    position: relative;
    right: 0;
  }
}
.header__social-row {
  margin-top: 25px;
}
@media (max-width: 767px) {
  .header__social-row {
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .header__social-links {
    display: block;
    margin: 10px auto;
    text-align: center;
  }
}
.header__social-link {
  display: inline-block;
  text-align: center;
}
@media (min-width: 768px) {
  .header__social-link {
    width: 32%;
  }
}
.header__social-link img {
  border-radius: 50%;
  border: 3px solid #fff;
  width: 70px;
  height: 70px;
}
@media (min-width: 768px) {
  .header__social-link img {
    width: auto;
    height: auto;
  }
}
.header__all-products {
  font-family: 'Fredoka One', sans-serif;
  background-color: #f7c500;
}
@media (min-width: 768px) {
  .header__all-products {
    font-size: 1vw;
    width: 100%;
    padding: 14px 0px 14px 0px;
  }
}
@media (min-width: 1200px) {
  .header__all-products {
    font-size: 11.3px;
    padding: 16px 12.5px;
    letter-spacing: 1.1px;
  }
}
@media (max-width: 767px) {
  .header__all-products {
    display: block;
    margin-top: 22px;
    padding: 12px 0px;
    font-size: 20.3px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header__all-products {
    font-size: 1.7vw;
    width: 100%;
    padding: 19px 0 19px 0;
  }
}
.header__wrapper {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .header__wrapper {
    margin-bottom: 0px;
  }
}
.header__call {
  position: relative;
  max-width: 170px;
}
.header__call:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 22.3529%;
}
.header__call img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header__weather {
  max-width: 461px;
  position: relative;
  display: block;
  margin: 16px auto 0;
}
.header__weather:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 16.4859%;
}
.header__weather img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header__message {
  font-family: 'Fredoka One', sans-serif;
  color: #ff4849;
  text-transform: uppercase;
  font-size: 22px;
  margin-top: 30px;
  text-align: center;
}
@media (max-width: 767px) {
  .header__message {
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .header__message {
    margin-top: 0;
    text-align: left;
  }
}
.widgets {
  display: none;
}
@media (min-width: 768px) {
  .widgets {
    display: block;
    background-color: #ff4849;
    border-radius: 5px;
    padding: 17px;
    margin-top: 50px;
    padding-bottom: 74px;
  }
  .widgets__text {
    display: block;
    margin-bottom: 20px;
  }
  .widgets__area-checker {
    width: 50%;
  }
  .widgets .form-control {
    font-size: 12px;
  }
  .widgets .SearchDate {
    width: 40%;
    border-radius: 5px;
    border: none;
    padding: 2.19px;
    padding-left: 15px;
    padding-top: 3px;
    position: absolute;
    right: 28px;
    bottom: 74px;
    font-size: 12px;
  }
  .widgets .SearchCategory {
    width: 58%;
    border-radius: 5px;
    border: none;
    padding: 7.5px;
    position: absolute;
    margin-top: 14px;
    font-size: 12px;
  }
  .widgets .SearchButton {
    color: #fff;
    background-color: #ffa013;
    border-radius: 5px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    font-size: 2rem;
    position: absolute;
    right: 28px;
    bottom: 25.5px;
    padding: 3px 9px;
    font-family: 'Fredoka One', sans-serif;
  }
}
@media (min-width: 768px) and (min-width: 992px) and (max-width: 1199px) {
  .widgets .SearchCategory {
    width: 50%;
  }
}
/*#region topLinksMobile*/
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #2c5ca8;
  color: white !important;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
  color: #5d5d5d;
  font-size: 18px;
  line-height: 165%;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion topLinksMobile*/
.toplinks {
  margin: 20px 0px;
}
.toplinks__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  position: relative;
  list-style: none;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.toplinks__li:nth-child(5n + 1) a {
  background-color: #005aaa;
}
.toplinks__li:nth-child(5n + 1) a:hover {
  background-color: #0075dd !important;
}
.toplinks__li:nth-child(5n + 1) .toplinks__dropdown {
  background-color: #005aaa !important;
}
.toplinks__li:nth-child(5n + 2) a {
  background-color: #65bc45;
}
.toplinks__li:nth-child(5n + 2) a:hover {
  background-color: #84ca6a !important;
}
.toplinks__li:nth-child(5n + 2) .toplinks__dropdown {
  background-color: #65bc45 !important;
}
.toplinks__li:nth-child(5n + 3) a {
  background-color: #ffd909;
}
.toplinks__li:nth-child(5n + 3) a:hover {
  background-color: #ffe13c !important;
}
.toplinks__li:nth-child(5n + 3) .toplinks__dropdown {
  background-color: #ffd909 !important;
}
.toplinks__li:nth-child(5n + 4) a {
  background-color: #ec0b8e;
}
.toplinks__li:nth-child(5n + 4) a:hover {
  background-color: #f634a5 !important;
}
.toplinks__li:nth-child(5n + 4) .toplinks__dropdown {
  background-color: #ec0b8e !important;
}
.toplinks__li:nth-child(5n + 5) a {
  background-color: #e83b4d;
}
.toplinks__li:nth-child(5n + 5) a:hover {
  background-color: #ed6976 !important;
}
.toplinks__li:nth-child(5n + 5) .toplinks__dropdown {
  background-color: #e83b4d !important;
}
.toplinks__li:first-child .toplinks__link {
  margin-left: 0px;
}
.toplinks__li:last-child .toplinks__link {
  margin-right: 0px;
}
.toplinks__li .dropdown-toggle:focus {
  text-decoration: none;
}
.toplinks__link {
  position: relative;
  display: block;
  padding: 12px 0px;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 2.25rem;
  font-family: 'Fredoka One', sans-serif;
  border-radius: 10px;
  margin: 0px 10px;
  z-index: 10;
}
@media (min-width: 768px) and (max-width: 991px) {
  .toplinks__link {
    font-size: 1.65rem;
  }
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #fff;
}
.toplinks__dropdown {
  padding: 0;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  border-radius: 8px;
  margin-top: 5px;
}
@media (min-width: 1200px) {
  .toplinks__dropdown {
    margin-left: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .toplinks__dropdown {
    margin-left: 10px;
  }
}
.toplinks__dropdown-li {
  display: block;
}
.toplinks__dropdown-link {
  position: relative;
  padding: 7px 10px !important;
  font-size: 1.6rem;
  color: #fff !important;
  font-family: 'Fredoka One', sans-serif;
  background-color: transparent !important;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  color: #fff !important;
}
.banner #CarouselContainer {
  padding-top: 64.6666%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner .carousel-inner .item,
.banner .carousel-inner a,
.banner .carousel-inner img {
  height: 100%;
}
.banner .carousel-inner .item a,
.banner .carousel-inner a a,
.banner .carousel-inner img a,
.banner .carousel-inner .item img,
.banner .carousel-inner a img,
.banner .carousel-inner img img {
  width: 100%;
  height: 100% !important;
  display: inline-block;
  -o-object-fit: contain;
     object-fit: contain;
}
.banner__container {
  z-index: 1;
}
.banner__wrapper {
  margin: -50px 0px 0px;
  padding: 100px 0 80px;
  background-color: #5b44ae;
  position: relative;
}
.banner__wrapper:after {
  content: "";
  position: absolute;
  background-image: url('https://files.bookingonline.co.uk/image/upload/f_auto/themes/038/banner-lower.png');
  bottom: 0;
  height: 59px;
  width: 100%;
  background-size: auto 100%;
  background-position: center center;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
@media (max-width: 767px) {
  .banner__wrapper:after {
    display: none;
  }
}
.banner__wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('https://files.bookingonline.co.uk/image/upload/f_auto/themes/038/banner-background.png');
  bottom: -48px;
  z-index: 1;
}
@media (max-width: 767px) {
  .banner__wrapper {
    margin: 0px;
    padding: 0px;
  }
}
.banner__title {
  color: #fff;
  font-size: 4.6rem;
  font-family: 'Fredoka One', sans-serif;
  line-height: 5rem;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .banner__title {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 25px;
  }
}
.banner__desc {
  color: #fff;
  font-size: 2.2rem;
}
@media (max-width: 767px) {
  .banner__desc {
    text-align: center;
  }
}
.banner__btn {
  padding: 15px 32px;
  font-size: 2rem;
  margin-top: 56px;
  background-color: #ff4849;
  font-family: 'Fredoka One', sans-serif;
  z-index: 10;
  position: relative;
}
@media (max-width: 767px) {
  .banner__btn {
    margin: 35px 0px;
    display: block;
  }
}
.banner__btn:hover {
  background-color: #ff2e2e;
}
.banner #CarouselContainer {
  max-height: none !important;
  max-width: none !important;
}
@media (max-width: 767px) {
  .banner #CarouselContainer {
    margin-bottom: 50px;
  }
}
.banner .carousel .item {
  height: 100%;
}
.banner .carousel img {
  width: 100% !important;
  height: 100% !important;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center center;
     object-position: center center;
}
.featured-cats {
  margin-top: -55px;
  z-index: 10;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featured-cats {
    margin-top: -40px;
  }
}
@media (max-width: 767px) {
  .featured-cats {
    margin-top: 20px;
  }
}
.featured-cats__panel {
  padding: 25px 15px;
  border-radius: 25px;
  min-height: 485px;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featured-cats__panel {
    padding: 25px 15px;
    min-height: 0px;
    margin-bottom: 35px;
  }
}
@media (max-width: 767px) {
  .featured-cats__panel {
    padding: 0 25px 0 25px;
    min-height: 0px;
    margin-bottom: 35px;
  }
}
.featured-cats__panel-parent:nth-child(1) .featured-cats__panel {
  background-color: #219cfc;
}
.featured-cats__panel-parent:nth-child(1) .featured-cats__btn {
  background-color: #c429a0;
  border-color: #c429a0;
}
.featured-cats__panel-parent:nth-child(2) .featured-cats__panel {
  background-color: #9dc536;
}
.featured-cats__panel-parent:nth-child(2) .featured-cats__btn {
  background-color: #219cfc;
  border-color: #219cfc;
}
.featured-cats__panel-parent:nth-child(3) .featured-cats__panel {
  background-color: #fd7232;
}
.featured-cats__panel-parent:nth-child(3) .featured-cats__btn {
  background-color: #9dc536;
  border-color: #9dc536;
}
.featured-cats__panel-parent:nth-child(4) .featured-cats__panel {
  background-color: #c429a0;
}
.featured-cats__panel-parent:nth-child(4) .featured-cats__btn {
  background-color: #fd7232;
  border-color: #fd7232;
}
.featured-cats__title {
  font-family: 'Fredoka One', sans-serif;
  text-transform: uppercase;
  color: #fff;
  font-size: 3.5rem;
  margin: 10px 0px 28px;
  line-height: 1;
}
@media (max-width: 767px) {
  .featured-cats__title {
    margin: 10px 0 25px;
    padding-top: 25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .featured-cats__title {
    padding: 0 40px 0 40px;
  }
}
.featured-cats__desc {
  color: #fff;
  font-size: 18px;
  margin-bottom: 36px;
}
.featured-cats img {
  min-height: 163px;
}
.featured-cats__btn {
  color: #fff;
  padding: 10px 30px;
  font-size: 2rem;
  font-family: 'Fredoka One', sans-serif;
  top: 45px;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .featured-cats__btn {
    top: 45px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .featured-cats__btn {
    top: 45px;
  }
}
@media (max-width: 767px) {
  .featured-cats__btn {
    top: 20px;
  }
}
.featured-cats--multiple #CarouselContainer {
  padding-top: 71.75%;
  overflow: hidden;
}
.featured-cats--multiple #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.featured-cats--multiple #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.featured-cats--multiple #CarouselContainer .carousel-inner .item a img,
.featured-cats--multiple #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.featured-cats--multiple .carousel {
  padding-top: 71.75%;
  overflow: hidden;
}
.featured-cats--multiple .carousel .carousel-inner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.featured-cats--multiple .carousel .carousel-inner .item,
.featured-cats--multiple .carousel .carousel-inner a,
.featured-cats--multiple .carousel .carousel-inner img {
  width: 100%;
  height: 100%;
}
.featured-cats--multiple .carousel .carousel-inner img {
  -o-object-fit: contain;
     object-fit: contain;
}
.main-content__wrapper {
  padding: 45px;
}
@media (max-width: 767px) {
  .main-content__wrapper {
    padding: 0px;
  }
}
.main-content__video {
  margin-top: 70px;
}
@media (max-width: 767px) {
  .main-content__video {
    margin-top: 25px;
    margin-bottom: 40px;
  }
}
.main-content__girl {
  display: none;
}
@media (min-width: 1200px) {
  .main-content__girl {
    display: block;
    position: absolute;
    left: -20px;
    top: 120px;
  }
}
#BodyContent {
  background-color: #fff;
}
@media (max-width: 767px) {
  #BodyContent {
    border-top-width: 0px;
  }
}
@media (min-width: 768px) {
  #BodyContent {
    padding-bottom: 50px;
  }
}
.pageListingsSection {
  background-color: #ff4849;
  padding-bottom: 65px;
  position: relative;
}
.pageListingsSection__header {
  color: #fff;
  text-align: center;
  font-family: 'Fredoka One', sans-serif;
  margin: 40px 0 40px;
  font-size: 4rem;
}
@media (max-width: 767px) {
  .pageListingsSection__header {
    margin: 35px 0 35px;
    font-size: 3.4rem;
  }
}
.pageListingsSection__boy {
  display: none;
}
@media (min-width: 1200px) {
  .pageListingsSection__boy {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.castlePanel {
  width: 100%;
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 8px;
}
@media (max-width: 767px) {
  .castlePanel {
    margin: 0px 0px 15px;
  }
}
.castlePanel:hover .SingleImagePanel img {
  -webkit-transform: none;
          transform: none;
  -webkit-animation-name: jello;
          animation-name: jello;
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.castlePanel .SingleImagePanel {
  height: auto;
  padding-top: 50%;
  border-radius: 8px;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  display: block;
  padding-top: 50%;
  width: 100%;
}
.castlePanel .castleTitlePanel {
  margin: 10px 0 0;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: 'Fredoka One', sans-serif;
}
.castlePanel .castleTitlePanel a {
  color: #000;
  font-size: 1.8rem;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0;
  margin-top: 10px;
}
.castlePanel .castleCheckBook {
  margin: 8px 38px;
  height: auto;
  background-color: #c049ff;
  border-radius: 8px;
  padding: 12px;
  padding: 7px;
  font-size: 1.6rem;
}
@media (max-width: 767px) {
  .castlePanel .castleCheckBook {
    margin: 12px 15px;
  }
}
#Testimonials {
  position: relative;
}
#Testimonials h2 {
  text-align: center;
}
#Testimonials .TestimonialContainer {
  color: #000 !important;
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 65px 32px 65px;
}
@media (max-width: 767px) {
  #Testimonials .TestimonialContainer {
    padding: 13px 15px;
  }
}
#Testimonials #TestimonialContent {
  padding: 55px 0px;
  background-color: #162050;
}
@media (min-width: 768px) and (max-width: 1199px) {
  #Testimonials #TestimonialContent {
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media (max-width: 767px) {
  #Testimonials #TestimonialContent {
    padding: 20px 0px;
  }
}
#Testimonials #TestimonialContent .Testimonial {
  text-decoration: none;
  text-align: center;
  display: block;
  overflow: hidden;
}
@media (min-width: 768px) {
  #Testimonials #TestimonialContent .Testimonial {
    padding-bottom: 25px;
  }
}
#Testimonials #TestimonialContent .Testimonial:hover {
  text-decoration: none;
}
#Testimonials #TestimonialContent .Testimonial p {
  font-size: 25px;
  color: #7bba02;
  text-decoration: none;
  text-align: center;
}
#Testimonials #TestimonialContent .Testimonial p:nth-child(1) {
  color: #000;
  font-size: 20px;
  font-style: italic;
}
.enquiryform {
  background-color: #f7c500;
}
@media (min-width: 768px) and (max-width: 991px) {
  .enquiryform__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.enquiryform__form-wrapper {
  margin-top: 27px;
}
@media (max-width: 767px) {
  .enquiryform__form-wrapper {
    margin-top: 15px;
  }
}
@media (min-width: 1200px) {
  .enquiryform__image-left {
    display: block;
    position: absolute;
    left: -85px;
    top: 84px;
    -webkit-transform: scale(0.9);
    transform: scale(1.2);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .enquiryform__image-left {
    display: block;
    position: absolute;
    left: 0px;
    top: 84px;
    -webkit-transform: scale(0.9);
    transform: scale(1);
  }
}
.enquiryform .sectionTitle {
  display: none;
}
.enquiryform .Label-YourName,
.enquiryform .Label-YourEmailAddress,
.enquiryform .Label-YourPhoneNumber,
.enquiryform .Label-Message,
.enquiryform .Label-YourLocation,
.enquiryform .Label-Name,
.enquiryform .Label-Email,
.enquiryform .Label-Phone,
.enquiryform .Label-Message,
.enquiryform .Label-Location {
  display: none;
}
.enquiryform .ControlYourPhoneNumber,
.enquiryform .ControlYourName,
.enquiryform .ControlYourEmailAddress,
.enquiryform .ControlMessage,
.enquiryform .ControlYourLocation,
.enquiryform .ControlPhone,
.enquiryform .ControlName,
.enquiryform .ControlEmail {
  background-color: #fff;
  color: #181818;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 100%;
  height: 60px;
}
.enquiryform .ControlYourPhoneNumber::-webkit-input-placeholder,
.enquiryform .ControlYourName::-webkit-input-placeholder,
.enquiryform .ControlYourEmailAddress::-webkit-input-placeholder,
.enquiryform .ControlMessage::-webkit-input-placeholder,
.enquiryform .ControlYourLocation::-webkit-input-placeholder,
.enquiryform .ControlPhone::-webkit-input-placeholder,
.enquiryform .ControlName::-webkit-input-placeholder,
.enquiryform .ControlEmail::-webkit-input-placeholder {
  color: #181818;
}
.enquiryform .ControlYourPhoneNumber::-moz-placeholder,
.enquiryform .ControlYourName::-moz-placeholder,
.enquiryform .ControlYourEmailAddress::-moz-placeholder,
.enquiryform .ControlMessage::-moz-placeholder,
.enquiryform .ControlYourLocation::-moz-placeholder,
.enquiryform .ControlPhone::-moz-placeholder,
.enquiryform .ControlName::-moz-placeholder,
.enquiryform .ControlEmail::-moz-placeholder {
  color: #181818;
}
.enquiryform .ControlYourPhoneNumber::-ms-input-placeholder,
.enquiryform .ControlYourName::-ms-input-placeholder,
.enquiryform .ControlYourEmailAddress::-ms-input-placeholder,
.enquiryform .ControlMessage::-ms-input-placeholder,
.enquiryform .ControlYourLocation::-ms-input-placeholder,
.enquiryform .ControlPhone::-ms-input-placeholder,
.enquiryform .ControlName::-ms-input-placeholder,
.enquiryform .ControlEmail::-ms-input-placeholder {
  color: #181818;
}
.enquiryform .ControlYourPhoneNumber::placeholder,
.enquiryform .ControlYourName::placeholder,
.enquiryform .ControlYourEmailAddress::placeholder,
.enquiryform .ControlMessage::placeholder,
.enquiryform .ControlYourLocation::placeholder,
.enquiryform .ControlPhone::placeholder,
.enquiryform .ControlName::placeholder,
.enquiryform .ControlEmail::placeholder {
  color: #181818;
}
.enquiryform .ControlMessage {
  height: 120px;
}
.enquiryform .innerColumn {
  padding: 0px;
}
.enquiryform .innerColumn .col-sm-6 {
  width: 100%;
}
.enquiryform .booking-form {
  max-width: 540px;
  margin: 0px;
  border: 0;
  background-color: transparent;
  border-radius: 0 0 0 0;
  color: #4d4d4d;
  -webkit-box-shadow: 0px 0px 0px #000;
          box-shadow: 0px 0px 0px #000;
}
@media (max-width: 767px) {
  .enquiryform .booking-form {
    margin: 0 auto 30px;
  }
}
.enquiryform h3 {
  font-family: 'Fredoka One', sans-serif;
  font-size: 5rem;
  color: #181818;
}
.enquiryform p {
  font-size: 18px;
  margin: 30px 0px;
}
.enquiryform__form-text {
  margin-bottom: 30px;
  max-width: 540px;
}
@media (max-width: 767px) {
  .enquiryform__form-text {
    text-align: center;
    margin: 0 auto 30px;
  }
}
.enquiryform input[type='button'] {
  font-family: 'Fredoka One', sans-serif;
  color: white;
  background-color: #181818;
  letter-spacing: 2px;
  font-size: 2rem;
  padding: 15px;
  margin: 20px 0px 40px;
  width: 38%;
  border-color: #181818;
}
@media (max-width: 767px) {
  .enquiryform input[type='button'] {
    padding: 8px;
    margin: 20px 0 0;
    width: 60%;
  }
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #2c5ca8;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 180px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.footer__top-wrapper {
  background: #5640a9;
  padding: 50px 0px 25px;
}
@media (max-width: 767px) {
  .footer__top-wrapper {
    text-align: center;
  }
}
.footer__bottom-wrapper {
  background: #3F4040;
  padding: 20px 0px;
}
@media (max-width: 767px) {
  .footer__bottom-wrapper {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .footer__call-info {
    text-align: center;
    margin-top: 20px;
  }
}
.footer__call {
  position: relative;
}
@media (max-width: 767px) {
  .footer__call {
    position: relative;
    left: 42px;
  }
}
.footer__phone img {
  position: relative;
  right: 22px;
  width: 85%;
}
@media (max-width: 767px) {
  .footer__phone img {
    position: relative;
    right: 0;
  }
}
.footer__logos ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 0px 0px 0px;
  list-style: none;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 767px) {
  .footer__logos ul {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.footer__logos ul li {
  list-style: none;
}
.footer__logos li img,
.footer__logos a {
  display: inline-block;
  color: white;
  text-align: center;
  margin: 5px;
  text-decoration: none;
}
.footer__logos #HTTPS {
  width: 95px;
  height: 75px;
}
.footer__logos #BIHA {
  width: 65px;
  height: 70px;
}
.footer__logos #TIPE {
  width: 67px;
  height: 70px;
}
.footer__logos #PIPA {
  width: 109px;
  height: 70px;
}
@media (min-width: 768px) {
  .footer #BCNLink {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
    display: grid;
  }
}
@media (max-width: 767px) {
  .footer #BCNLink {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: grid;
  }
}
.footer__logo-container {
  padding: 0px 0px;
}
.footer__Https {
  position: relative;
  top: 10px;
}
.footer__email {
  color: #fff;
  font-size: 2rem;
  margin-top: 25px;
  font-family: 'Fredoka One', sans-serif;
  letter-spacing: 1.6px;
}
.footer__email:hover {
  color: #fff;
}
@media (min-width: 768px) {
  .footer__email {
    font-size: 1.8rem;
  }
}
@media (min-width: 1200px) {
  .footer__email {
    font-size: 2rem;
  }
}
.footer__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 10px 0;
}
.footer__link {
  color: #fff;
  position: relative;
  display: table;
  margin: 0 5px;
  padding: 10px 5px;
}
.footer__link:after {
  content: '';
  position: absolute;
  bottom: -0.2rem;
  left: 0;
  right: 100%;
  height: 0px;
  background: #ffffff;
  -webkit-transition: left 2s, right 2s, height 2s, opacity 1s;
  transition: left 2s, right 2s, height 2s, opacity 1s;
  opacity: 0;
}
.footer__link:hover {
  color: #fff;
  text-decoration: none !important;
}
.footer__link:hover:after {
  left: 0;
  right: 0;
  height: 2px;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transition: left 0.5s, right 0.5s, height 0.5s, opacity 1s;
  transition: left 0.5s, right 0.5s, height 0.5s, opacity 1s;
}
.footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767px) {
  .footer__social {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.footer__social-link img {
  margin: 0 2px;
  max-width: 40px !important;
}
@media (max-width: 767px) {
  .footer__social-link img {
    margin: 0 4px;
  }
}
.footer__row {
  color: #a1a1a1;
}
.footer__row p,
.footer__row a {
  color: #a1a1a1;
}
.footer__row align-content:hover {
  color: #a1a1a1;
  text-decoration: none;
}
@media (min-width: 768px) {
  .footer__booking-online {
    text-align: right;
  }
}
.footer__navigation-links a {
  color: #fff;
}
.section-title {
  font-family: 'Fredoka One', sans-serif;
  color: #fff;
  font-size: 2.4rem;
  text-transform: uppercase;
}
.lower-content {
  background: #fff;
  border-radius: 8px;
  padding: 10px 20px 15px;
  margin-top: 40px;
}
/*#region Animations*/
@-webkit-keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
            transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
            transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
            transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
            transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
            transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
            transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
            transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
@keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
            transform: skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
            transform: skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
            transform: skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
            transform: skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
            transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
            transform: skewX(0.390625deg) skewY(0.390625deg);
  }
  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
            transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}
/*#endregion Animations*/
